import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, UPDATE_INDEX } from '../../vinter/constants/ActionTypes';

export const fetchSuccess = message => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || '',
    });
  };
};
export const fetchError = error => {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
  };
};

export const setIndex = index => {
  return dispatch => {
    dispatch({
      type: UPDATE_INDEX,
      payload: index,
    });
  };
};
