// Stock chart requires three amCharts 5 modules: "index", "xy", and "stock".
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5stock from '@amcharts/amcharts5/stock';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import React, { useLayoutEffect } from 'react';

const StockChart = ({ chartID }) => {
  useLayoutEffect(() => {
    /* Chart code */
    // Create root element
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new(chartID);

    // Set themes
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create a stock chart
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Instantiating_the_chart
    let stockChart = root.container.children.push(am5stock.StockChart.new(root, {}));

    // Set global number format
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/concepts/formatters/formatting-numbers/
    root.numberFormatter.set('numberFormat', '#,###.00');

    // Create a main stock panel (chart)
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Adding_panels
    let mainPanel = stockChart.panels.push(
      am5stock.StockPanel.new(root, {
        wheelY: 'zoomX',
        panX: true,
        panY: true,
      }),
    );

    // Create value axis
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let valueAxis = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          pan: 'zoom',
        }),
        extraMin: 0.1, // adds some space for main series
        tooltip: am5.Tooltip.new(root, {}),
        numberFormat: '#,###.00',
        extraTooltipPrecision: 2,
      }),
    );

    let dateAxis = mainPanel.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    // Add series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let valueSeries = mainPanel.series.push(
      am5xy.LineSeries.new(root, {
        name: 'MSFT',
        xAxis: dateAxis,
        yAxis: valueAxis,
        valueYField: 'Close',
        valueXField: 'Date',
        stroke: am5.color('rgba(117, 48, 255, 0.8)'),
        // legendValueText: "[bold]{valueY.formatNumber('#,###.0a')}[/]",
      }),
    );

    console.log('Value series: ', valueSeries);

    // Set main value series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Setting_main_series
    stockChart.set('stockSeries', valueSeries);

    // Add a stock legend
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock-chart/stock-legend/
    let valueLegend = mainPanel.plotContainer.children.push(
      am5stock.StockLegend.new(root, {
        stockChart: stockChart,
      }),
    );

    let volumeValueAxis = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          pan: 'zoom',
        }),
        extraMin: 0.1, // adds some space for main series
        tooltip: am5.Tooltip.new(root, {}),
        numberFormat: '#,###.00',
        extraTooltipPrecision: 2,
      }),
    );

    let volumeSeries = mainPanel.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Volume',
        clustered: false,
        valueXField: 'Date',
        valueYField: 'Volume',
        xAxis: dateAxis,
        yAxis: volumeValueAxis,
        stroke: am5.color('rgba(215, 19, 113, 0.8)'),
        // legendValueText: "[bold]{valueY.formatNumber('#,###.0a')}[/]",
      }),
    );

    // Set main series
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock-chart/#Setting_main_series
    stockChart.set('volumeSeries', volumeSeries);
    valueLegend.data.setAll([valueSeries, volumeSeries]);

    // Add cursor(s)
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    mainPanel.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        yAxis: valueAxis,
        xAxis: dateAxis,
        snapToSeries: [valueSeries],
        snapToSeriesBy: 'y!',
      }),
    );

    // Add scrollbar
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    let scrollbar = mainPanel.set(
      'scrollbarX',
      am5xy.XYChartScrollbar.new(root, {
        orientation: 'horizontal',
        height: 20,
      }),
    );
    stockChart.toolsContainer.children.push(scrollbar);

    let sbDateAxis = scrollbar.chart.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    );

    let sbValueAxis = scrollbar.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    let sbSeries = scrollbar.chart.series.push(
      am5xy.LineSeries.new(root, {
        valueYField: 'Close',
        valueXField: 'Date',
        xAxis: sbDateAxis,
        yAxis: sbValueAxis,
      }),
    );

    sbSeries.fills.template.setAll({
      visible: true,
      fillOpacity: 1,
    });

    // Function that dynamically loads data
    function loadData(ticker, series, granularity) {
      // Load external data
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Setting_data
      am5.net
        .load('https://www.amcharts.com/wp-content/uploads/assets/docs/stock/' + ticker + '_' + granularity + '.csv')
        .then(function(result) {
          // Parse loaded data
          let data = am5.CSVParser.parse(result.response, {
            delimiter: ',',
            skipEmpty: true,
            useColumnNames: true,
          });

          // console.log('Last three months: ', data);
          // Process data (convert dates and values)
          let processor = am5.DataProcessor.new(root, {
            dateFields: ['Date'],
            dateFormat: 'yyyy-MM-dd',
            numericFields: ['Open', 'Close', 'Volume'],
          });
          processor.processMany(data);
          // console.log('data: ', data.slice(-90));
          // data = data.slice(-90);

          // Set data
          am5.array.each(series, function(item) {
            item.data.setAll(data);
          });
        });
    }

    // Load initial data for the first series
    let currentGranularity = 'day';
    loadData('MSFT', [valueSeries, volumeSeries, sbSeries], currentGranularity);

    // Stock toolbar
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/stock/toolbar/
    let toolbar = am5stock.StockToolbar.new(root, {
      container: document.getElementById('chartcontrols'),
      stockChart: stockChart,
      controls: [
        am5stock.PeriodSelector.new(root, {
          stockChart: stockChart,
        }),
      ],
    });
  }, [chartID]);

  return <div id={chartID}></div>;
};

export default StockChart;
