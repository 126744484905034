import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'

import { Grid, Box, responsiveFontSizes } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../vinter/components/GridContainer';
import PageContainer from '../../../vinter/components/PageComponents/layouts/PageContainer';
import { setIndex } from '../../../redux/actions';
import axios from 'axios';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../../vinter/constants/AppConstants';

import IndexHistory from './IndexHistory';

const token = localStorage.getItem('token');
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

var getConfig = {
  method: 'get',
  url: 'https://etp.vinter.co/api/v1/nav/indexes',
};
const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Shop', link: '/shop' },
  { label: 'List', isActive: true },
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [is_error, setisError] = useState(false)
  const [is_updated, setisUpdated] = useState(false)
  const [indxes, setIndxes] = useState([])
  const selectedIndex = useSelector(({ common }) => common.selectedIndex);
  const { authUser } = useSelector(({ auth }) => auth);


  const onClick = (index) => {
    console.log("clicked");
    dispatch(setIndex(index));
    console.log(selectedIndex, "selectedIndex");
    dispatch(AuhMethods[CurrentAuthMethod].getNav(false, index))
    history.push(`/apps`);
  }


  const indexes = async () => {
    console.log("Loading from db")
    const response = await axios(getConfig).then((response) => {
      if (response) {

        console.log(response, "response")
        setIndxes(response.data)
      } else {
        setisError(true)

      }
    })
      .catch(error => {
        setisError(true)
        if (error.response.status === 403) {
          window.location.href = '/signin';
        }

      });
    // console.log('response', response)


  }

  useEffect(() => {
    dispatch(setIndex(''));

    indexes();
  }, [])


  return (
    <PageContainer heading="Vinter MarketPlace" breadcrumbs={breadcrumbs}>

      {/* <script async src="https://js.stripe.com/v3/pricing-table.js"></script> */}
      <stripe-pricing-table pricing-table-id="prctbl_1MW7X0LEwnxGsPXpjZz4ScQs"
        publishable-key="pk_test_51JFleeLEwnxGsPXpKmoPBIAG0aEfKEhEtINGqdiwxUyaldtjiHRVaQUE0zyVYmfEu8y7is090rcTwm7hzBj4w99R00e9sU38ma"
        customer-email={authUser.email}>
      </stripe-pricing-table>
    </PageContainer>
  );
};

export default Dashboard;
