import React, { useEffect, useState } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import ApikeyListRow from './ApikeyListRow';
import ApikeyTableHead from './ApikeyTableHead';
import ApikeyTableToolbar from './ApikeyTableToolbar';
import { getComparator, stableSort } from '../../../vinter/utils/tableHelper';
import { useDispatch, useSelector } from 'react-redux';
import { deleteApikey, getApikeys, setCurrentApikey } from '../../../redux/actions/Apikeys';
import AddEditApikey from './AddEditApikey';
import ConfirmDialog from '../../../vinter/components/Common/ConfirmDialog';
import { useDebounce } from '../../../vinter/utils/commonHelper';
import useStyles from './index.style';
import ApikeyDetailView from './ApikeyDetailView';
import NoRecordFound from './NoRecordFound';

const ApikeysModule = () => {
  const classes = useStyles();
  const { apikeys } = useSelector(({ apikeysReducer }) => apikeysReducer);
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openApikeyDialog, setOpenApikeyDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedApikey, setSelectedApikey] = useState({ name: '' });
  const [apikeysFetched, setApikeysFetched] = useState(false);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getApikeys(filterOptions, debouncedSearchTerm, () => {
        setFilterApplied(!!filterOptions.length || !!debouncedSearchTerm);
        setApikeysFetched(true);
      }),
    );
  }, [dispatch, filterOptions, debouncedSearchTerm]);

  const handleCloseApikeyDialog = () => {
    setOpenApikeyDialog(false);
    dispatch(setCurrentApikey(null));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = apikeys.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApikeyView = apikey => {
    dispatch(setCurrentApikey(apikey));
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    dispatch(setCurrentApikey(null));
  };

  const handleApikeyEdit = apikey => {
    dispatch(setCurrentApikey(apikey));
    setOpenApikeyDialog(true);
  };

  const handleApikeyDelete = apikey => {
    setSelectedApikey(apikey);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteApikey(selectedApikey.id));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;



  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ApikeyTableToolbar
          selected={selected}
          setSelected={setSelected}
          onApikeyAdd={setOpenApikeyDialog}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
            <ApikeyTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={apikeys.length}
            />
            <TableBody>
              {!!apikeys.length ? (
                stableSort(apikeys, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <ApikeyListRow
                      key={index}
                      row={row}
                      onRowClick={handleRowClick}
                      onApikeyEdit={handleApikeyEdit}
                      onApikeyDelete={handleApikeyDelete}
                      onApikeyView={handleApikeyView}
                      isSelected={isSelected}
                    />
                  ))
              ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      {isFilterApplied ? (
                        <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                      ) : (
                          <NoRecordFound>{apikeysFetched ? 'There are no records found.' : 'Loading apikeys...'}</NoRecordFound>
                        )}
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={apikeys.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>

      {openApikeyDialog && <AddEditApikey open={openApikeyDialog} onCloseDialog={handleCloseApikeyDialog} />}
      {openViewDialog && <ApikeyDetailView open={openViewDialog} onCloseDialog={handleCloseViewDialog} />}

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete ${selectedApikey.name}`}
        content={'Are you sure, you want to  delete this apikey?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default ApikeysModule;
