import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import AppTextInput from '../../../../vinter/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import { requiredMessage } from '../../../../vinter/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { addNewApikey } from '../../../../redux/actions/Apikeys';


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

const AddEditApikey = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const currentApikey = useSelector(({ apikeysReducer }) => apikeysReducer.currentApikey);
  const { authUser } = useSelector(({ auth }) => auth);

  const [name, setName] = useState('');

  const [organization, setOrganization] = useState(authUser.organization);
  const [nameError, setNameError] = useState('');


  const dispatch = useDispatch();

  useEffect(() => {
    console.log("currentApikey", currentApikey)
    if (currentApikey) {
      setName(currentApikey);
    }
  }, [currentApikey]);

  const onSubmitClick = () => {
    if (!name) {
      setNameError(requiredMessage);
    }
    else {
      onApikeySave();
    }
  };

  const onApikeySave = () => {
    console.log("Auth =>", authUser)
    const apikeyDetail = {
      name: `${name}_${organization}`,
      organization,
      organization_id: authUser.organization_id ? authUser.organization_id : authUser.id,
      // designation,
    };
    dispatch(
      addNewApikey(apikeyDetail, () => {
        onCloseDialog();
      }),
    );
  };


  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentApikey ? 'Edit Apikey Details' : 'Create New API Key'}</DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 12 }}>
          {/* <Box {...getRootProps()} mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className="pointer">
            <input {...getInputProps()} />
            <CmtAvatar size={70} src={profile_pic} />
          </Box> */}

          <AppTextInput
            fullWidth
            variant="outlined"
            label="Name"
            value={name}
            onChange={e => {
              setName(e.target.value);
              setNameError('');
            }}
            helperText={nameError}
          />

        </Box>


        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditApikey;

AddEditApikey.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
