import React, { useEffect, useState } from 'react';
import { marketIndexesData } from '../../../../@fake-db';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import MarketIndexComp from './MarketIndexComp';
import Typography from '@material-ui/core/Typography';

const MarketIndex = () => {
  const [selectedProperty, setSelectedProperty] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState({});

  useEffect(() => {
    const values = getFilteredData();
    setData(values);
  }, [searchText]);

  const getFilteredData = () => {
    if (searchText !== '')
      return marketIndexesData.filter(item =>
        (item['tags'].join(' ') + ' ' + item.name).toLowerCase().includes(searchText.toLowerCase()),
      );
    return marketIndexesData;
  };

  const handleSearchTextChange = e => {
    setSearchText(e.target.value);
  };

  const handlePropertyClick = (property, itemNo) => {
    if (selectedProperty !== null) {
      if (selectedProperty?.itemNo === itemNo) return showPropertyList();
      setSelectedProperty({ property, itemNo });
    } else {
      // eslint-disable-next-line no-console
      console.log('Item Number: ', itemNo);
      setSelectedProperty({ property, itemNo });
    }
  };

  const showPropertyList = () => setSelectedProperty(null);

  return (
    <React.Fragment>
      <Box sx={{ position: 'relative', marginTop: '20px' }}>
        <Typography style={{ color: '#1d3955', fontWeight: '900', fontSize: '22px', textAlign: 'center' }}>
          Market Indexes
        </Typography>
        <Typography style={{ color: 'rgba(29, 57, 85, 0.5)', fontWeight: '400', fontSize: '16px', textAlign: 'center' }}>
          A market index is a passive index designed to track the returns of the crypto market.
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            top: 24,
            right: 20,
            display: 'flex',
            maxWidth: '500px',
            justifyContent: 'right',
            alignItems: 'center',
            background: '#fff',
            border: 'rgba(29, 57, 85, 0.6) solid 1pt',
            borderRadius: '25px',
          }}>
          <SearchIcon style={{ color: 'rgba(29, 57, 85, 0.6)', marginLeft: '15px' }} />
          <input
            type="text"
            placeholder="Search here..."
            value={searchText}
            onChange={handleSearchTextChange}
            style={{
              display: 'flex',
              flex: 1,
              maxWidth: '450px',
              fontSize: '16px',
              fontWeight: 500,
              color: 'rgba(29, 57, 85, 0.6)',
              border: 'none',
              outline: 'none',
              borderRadius: '25px',
              padding: '10px 10px',
            }}
          />
        </Box>
      </Box>
      <Box sx={{ width: '100%', borderTop: 'rgba(29, 57, 85, 0.3) solid 1pt', margin: '30px 0 0' }}></Box>
      <MarketIndexComp
        handlePropertyClick={handlePropertyClick}
        data={data}
        searchText={searchText}
        handleSearchTextChange={handleSearchTextChange}
        selectedProperty={selectedProperty}
        showPropertyList={showPropertyList}
      />
    </React.Fragment>
  );
};

export default MarketIndex;
