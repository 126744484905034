import React from 'react';
import PropertiesList from './PropertiesList';

const MarketIndexComp = ({
  handlePropertyClick,
  tabValue,
  onChangeTab,
  data,
  searchText,
  handleSearchTextChange,
  handlePageChange,
  selectedProperty,
  showPropertyList,
}) => {
  return (
    <React.Fragment>
      <PropertiesList
        onPropertyClick={handlePropertyClick}
        tabValue={tabValue}
        onChangeTab={onChangeTab}
        data={data}
        searchText={searchText}
        handleSearchTextChange={handleSearchTextChange}
        handlePageChange={handlePageChange}
        selectedProperty={selectedProperty}
        showPropertyList={showPropertyList}
      />
    </React.Fragment>
  );
};

export default MarketIndexComp;
