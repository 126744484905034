import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          console.log("data", data)
          if (data.id) {

            // localStorage.setItem('token', data.id);
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
            dispatch(fetchSuccess("Registeration Done"));
            dispatch(JWTAuth.getAuthUser(true, data.access_token));
            window.location.href = '/signin'
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function (error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password, otp }) => {
    var bodyFormData = new FormData();
    bodyFormData.append('username', email)
    bodyFormData.append('password', password)
    bodyFormData.append('otp', otp)

    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('auth/jwt/login', bodyFormData)
          .then(async ({ data }) => {
            console.log("data", data)
            if (data.access_token) {
              localStorage.removeItem('token');
              localStorage.removeItem('originaldata');
              localStorage.removeItem('nav_id');

              // if check otp
              let otpresponse = await axios
                .get('otp/verify/' + (otp ? otp : 0), { headers: { 'Authorization': 'Bearer ' + data.access_token } })
                .then(({ data }) => {
                  return data;
                })

              if (otpresponse) {
                localStorage.setItem('token', data.access_token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
                dispatch(fetchSuccess("Login Done"));
                dispatch(JWTAuth.getAuthUser(true, data.access_token));
                window.location.href = '/'
              } else {
                dispatch(fetchError("Invalid OTP"));
              }


            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function (error) {
            dispatch(fetchError("Invalid Credentials Please Try again!"));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/jwt/logout')
        .then(({ data }) => {
          // if (data.status === 200) {
          dispatch(fetchSuccess());
          localStorage.removeItem('token');
          localStorage.removeItem('originaldata');
          localStorage.removeItem('nav_id');
          dispatch(setAuthUser(null));
          // } else {
          //   dispatch(fetchError(data.error));
          // }
          window.location.href = "/signin"
        })
        .catch(function (error) {
          dispatch(fetchError(error.message));
          window.location.href = "/signin"
        });
    };
  },


  setNotification: (loaded = false, token, otpEnabled, emailEnabled, pcfFormat, eodFormat, navFormat, ptype) => {
    let bodyFormData = { 'otpEnabled': otpEnabled, 'emailEnabled': emailEnabled, 'pcfFormat': pcfFormat, 'eodFormat': eodFormat, 'navFormat': navFormat }

    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      const nav_id = localStorage.getItem("nav_id")
      // dispatch(updateLoadUser(loaded));
      let generalConfig = {}
      if (nav_id) {
        generalConfig.url = 'https://ficasapi.vinterindex.com/api/v1/subscription/update_subscription_email/' + nav_id + "?email_enabled=" + emailEnabled

        generalConfig.method = "put"
        axios(generalConfig)
          .then(function (response) {


            axios
              .patch('users/me', bodyFormData)
              .then(({ data }) => {
                if (data.id) {
                  console.log(data)
                  dispatch(fetchSuccess());
                  dispatch(setAuthUser(data));
                } else {
                  // dispatch(updateLoadUser(true));
                  window.location.href = "/signin"
                }
              })
              .catch(function (error) {
                //dispatch(updateLoadUser(true));
                if (window.location.pathname !== "/signin") {
                  window.location.href = "/signin"
                }

              });

          })
          .catch(function (error) {
            console.log(error);
            dispatch(fetchError("Error updating Portfolio!"))
            if (error.response.status === 403) {
              window.location.href = '/signin';
            }
          });
      }

    };
  },

  updateAuthUser: (loaded = false, token, otpEnabled, emailEnabled, pcfFormat, eodFormat, navFormat, ptype) => {
    let bodyFormData = { 'otpEnabled': otpEnabled, 'emailEnabled': emailEnabled, 'pcfFormat': pcfFormat, 'eodFormat': eodFormat, 'navFormat': navFormat }

    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      const nav_id = localStorage.getItem("nav_id")
      // dispatch(updateLoadUser(loaded));
      let generalConfig = {}
      if (nav_id) {
        generalConfig.url = 'https://ficasapi.vinterindex.com/api/v1/nav/' + nav_id
        generalConfig.data = {}
        if (ptype == 'email') {
          generalConfig.data = {
            "email_enabled": emailEnabled
          }
        }
        if (ptype == 'format') {
          generalConfig.data = {
            "pcf_format": pcfFormat,
            "nav_format": navFormat,
            "eod_format": eodFormat
          }
        }
        generalConfig.method = "put"
        axios(generalConfig)
          .then(function (response) {


            axios
              .patch('users/me', bodyFormData)
              .then(({ data }) => {
                if (data.id) {
                  console.log(data)
                  dispatch(fetchSuccess());
                  dispatch(setAuthUser(data));
                } else {
                  // dispatch(updateLoadUser(true));
                  window.location.href = "/signin"
                }
              })
              .catch(function (error) {
                //dispatch(updateLoadUser(true));
                if (window.location.pathname !== "/signin") {
                  window.location.href = "/signin"
                }

              });

          })
          .catch(function (error) {
            console.log(error);
            dispatch(fetchError("Error updating Portfolio!"))
            if (error.response.status === 403) {
              window.location.href = '/signin';
            }
          });
      }

    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('users/me')
        .then(({ data }) => {
          if (data.id) {
            console.log(data)
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          } else {
            dispatch(updateLoadUser(true));
            window.location.href = "/signin"
          }
        })
        .catch(function (error) {
          dispatch(updateLoadUser(true));
          if (window.location.pathname !== "/signin") {
            window.location.href = "/signin"
          }

        });
    };
  },

  getNav: (loaded = false, index, token) => {
    console.log("getnav", index)
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      if (!index) {
        window.location.href = "/dashboard"
      }
      dispatch(fetchStart());
      // dispatch(updateLoadUser(loaded));
      let generalConfig = {}
      generalConfig.url = 'https://ficasapi.vinterindex.com/api/v1/nav/ticker/' + index
      generalConfig.method = "get"
      axios(generalConfig)
        .then(function (response) {

          if (response) {
            localStorage.setItem("nav_id", response.data.id)
            dispatch(fetchSuccess());
            // dispatch(updateLoadUser(true));
          }

        })
        .catch(function (error) {
          console.log(error);
          dispatch(fetchError("Error Fetching Portfolio!"))
          if (error.response.status === 403) {
            window.location.href = '/signin';
          }
        });


    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
