import {
  ADD_SUBSCRIBER,
  DELETE_BULK_SUBSCRIBERS,
  DELETE_SUBSCRIBER,
  EDIT_SUBSCRIBER,
  GET_SUBSCRIBERS,
  SET_SUBSCRIBER_DETAILS,
} from '../../vinter/constants/ActionTypes';

const INIT_STATE = {
  subscribers: [],
  currentSubscriber: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIBERS: {
      return {
        ...state,
        subscribers: action.payload,
      };
    }
    case SET_SUBSCRIBER_DETAILS: {
      return {
        ...state,
        currentSubscriber: action.payload,
      };
    }
    case ADD_SUBSCRIBER: {
      return {
        ...state,
        subscribers: [action.payload, ...state.subscribers],
      };
    }
    case EDIT_SUBSCRIBER: {
      return {
        ...state,
        subscribers: state.subscribers.map(subscriber => (subscriber.id === action.payload.id ? action.payload : subscriber)),
      };
    }
    case DELETE_SUBSCRIBER: {
      return {
        ...state,
        subscribers: state.subscribers.filter(subscriber => subscriber.id !== action.payload),
      };
    }
    case DELETE_BULK_SUBSCRIBERS: {
      return {
        ...state,
        subscribers: state.subscribers.filter(subscriber => !action.payload.includes(subscriber.id)),
      };
    }
    default:
      return state;
  }
};
