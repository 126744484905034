import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'

import { Grid, Box, responsiveFontSizes } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../vinter/components/GridContainer';
import PageContainer from '../../../vinter/components/PageComponents/layouts/PageContainer';
import { setIndex } from '../../../redux/actions';
import axios from 'axios';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../../vinter/constants/AppConstants';

import IndexHistory from './IndexHistory';

const token = localStorage.getItem('token');
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

var getConfig = {
  method: 'get',
  url: 'https://ficasapi.vinterindex.com/api/v1/nav/indexes',
};
const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Dashboard', link: '/dashboard' },
  { label: 'Indexes', isActive: true },
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [is_error, setisError] = useState(false)
  const [is_updated, setisUpdated] = useState(false)
  const [indxes, setIndxes] = useState([])
  const selectedIndex = useSelector(({ common }) => common.selectedIndex);
  const { authUser } = useSelector(({ auth }) => auth);


  const onClick = (index) => {
    console.log("clicked");
    dispatch(setIndex(index));
    console.log(selectedIndex, "selectedIndex");
    dispatch(AuhMethods[CurrentAuthMethod].getNav(false, index))
    history.push(`/apps`);
  }


  const indexes = async () => {
    console.log("Loading from db")
    const response = await axios(getConfig).then((response) => {

      if (response) {
        console.log(response, "response")
        setIndxes(response.data)
      } else {
        setisError(true)

      }
    })
      .catch(error => {
        setisError(true)
        if (error.response.status === 403) {
          window.location.href = '/signin';
        }

      });
    // console.log('response', response)


  }

  useEffect(() => {
    dispatch(setIndex(''));

    indexes();
  }, [])


  return (
    <PageContainer heading="Indexes Dashboard" breadcrumbs={breadcrumbs}>


      <GridContainer>
        {indxes.map(index => {
          return (
            <Grid item xs={12} sm={6} md={3} onClick={evt => { onClick(index.index) }}>
              <IndexHistory key={index.index} index={index.full_name} amount={parseFloat(index.index_values[index.index_values.length - 1]).toFixed(2)} data={index.index_values} />
            </Grid>
          )
        })}
      </GridContainer>
      {/* <script async src="https://js.stripe.com/v3/pricing-table.js"></script> */}

    </PageContainer>
  );
};

export default Dashboard;
