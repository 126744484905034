import React from 'react';
import CmtList from '../../../../../@coremat/CmtList';
import Tooltip from '@material-ui/core/Tooltip';
import PropertyItem from './PropertyItem';
import { alpha, makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListEmptyResult from '../../../../../@coremat/CmtList/ListEmptyResult';
import PropertyDetail from '../PropertyDetail';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(theme => ({
  newsListRoot: {
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all .2s',
    '&:not(:first-child)': {
      borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    },
    '& .fav-btn': {
      opacity: 0,
      visibility: 'hidden',
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.05)}`,
      '& .fav-btn': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const PropertiesDataList = ({ data, onPropertyClick, showPropertyList, selectedProperty }) => {
  const classes = useStyles();
  console.log('show property list: ', selectedProperty);
  return (
    <CmtList
      data={data}
      renderRow={(item, index) => {
        const { details: { coinsStatus } = {} } = item;
        console.log('Item: ', coinsStatus);
        let value = 0;
        coinsStatus.map(itemi => (value += itemi.value));
        value = 100 / value;
        return (
          <>
            <ListItem component="div" className={classes.newsListRoot} key={index} disableGutters={true}>
              <PropertyItem item={item} onPropertyClick={onPropertyClick} itemNo={index + 1} />
              <Box sx={{ display: 'flex', width: '92%', padding: '0 0 7px 0' }}>
                {item?.tags?.map(tag => (
                  <Box
                    sx={{
                      padding: '5px 15px',
                      borderRadius: '15px',
                      fontSize: '11px',
                      fontWeight: 600,
                      marginRight: '8px',
                      background: '#e3e1e1',
                    }}>
                    {tag}
                  </Box>
                ))}
              </Box>
            </ListItem>
            <Box sx={{ width: '100%' }}>
              {selectedProperty && selectedProperty.itemNo === index + 1 ? (
                ''
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    height: '5px',
                  }}>
                  {coinsStatus.map((itemI, indexI) => {
                    return (
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">{itemI.name}</Typography>
                            <b>{'Value: ' + itemI.value}</b>
                          </React.Fragment>
                        }>
                        <Box
                          sx={{
                            opacity: 0.8,
                            cursor: 'pointer',
                            background: `${itemI.color}`,
                            borderRight: `${coinsStatus.length === indexI + 1 ? '' : '2px solid #fff'}`,
                            fontWeight: 700,
                            color: '#FFF',
                            width: `${value * itemI.value}%`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: ' 0.5px 0.5px 10px 0 rgba(0, 0, 0, 0.1)',
                          }}></Box>
                      </HtmlTooltip>
                    );
                  })}
                </Box>
              )}
            </Box>
            <Collapse in={selectedProperty?.itemNo} timeout="auto" unmountOnExit>
              {selectedProperty?.itemNo === index + 1 && <PropertyDetail selectedProperty={selectedProperty?.property} />}
            </Collapse>
          </>
        );
      }}
      ListEmptyComponent={<ListEmptyResult title="No Result" content="No result found with your search" />}
    />
  );
};

export default PropertiesDataList;
