import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, FormControl, InputLabel, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import Button from '@material-ui/core/Button';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { NavLink } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { gapi } from 'gapi-script';
import { Client } from '@hubspot/api-client';
import axios from '../../../../services/auth/jwt/config'
import { isValidEmail } from '../../../utils/commonHelper';
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },

  stripeButton: {
    width: '100%',
    '& button': {
      color: 'rgba(0, 0, 0, 0.54)',
      width: '100%',
      display: 'flex !important',
      justifyContent: 'center !important',
      boxShadow: 'none !important',
      height: '40px',
      fontSize: '14px',
      fontWeight: 500,
      border: '1px solid #d1d3d5 !important',
      borderRadius: '5px !important',
      overflow: 'hidden !important',
      padding: '10px 10px !important',
      cursor: 'pointer',
    },
  },
  authContent: {
    margin: '100px auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: props => (props.variant === 'default' ? '50%' : '600px'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  topComponent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  googleSignUpButton: {
    display: 'flex !important',
    justifyContent: 'center !important',
    boxShadow: 'none !important',
    height: '40px',
    border: '1px solid #d1d3d5 !important',
    borderRadius: '5px !important',
    overflow: 'hidden !important',
    padding: '8px 0 !important',
  },
  signUpMessage: {
    fontSize: '16px',
    fontWeight: 500,
    padding: '15px',
    borderRadius: '5px',
    background: 'rgba(164,243,155,0.52)',
  },
  errorMessage: {
    fontSize: '16px',
    fontWeight: 500,
    padding: '15px',
    borderRadius: '5px',
    background: 'rgba(251,165,165,0.45)',
  },
  backButtonRoot: {
    display: 'flex',
    columnGap: '15px',
    color: '#2874ec',
    cursor: 'pointer',
  },
  titleRoot: {
    color: theme.palette.text.primary,
    fontSize: '32px',
    fontWeight: 700,
  },
  divider: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0 20px',
    '& .horizontal_border': {
      width: '44%',
      height: '1px',
      backgroundColor: '#d1d3d5',
    },
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formControl: {
    width: '100%',
    marginTop: '14px',
  },

  createAccountButton: {
    width: '100%',
    marginTop: '40px',
    minHeight: '40px',
    textTransform: 'none',
    background: '#183554',
    color: '#ffffff',

  },

  textAcc: {
    marginTop: '24px',
    textAlign: 'left',
    fontSize: '12px',
    fontWeight: 400,
    '& .terms, & .privacy': {
      color: '#2874ec',
    },
  },
}));

const SignUp = ({ method = CurrentAuthMethod, wrapperVariant = 'default' }) => {
  const [signUpGoogle, setSignUpGoogle] = useState(false);
  const [signUpStatus, setSignUpStatus] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [company, setComapny] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();

  const clientId = '737571833371-tg3f1g843ph7iqa8839asclgcmesbb1l.apps.googleusercontent.com';

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  });

  const handleCreateNewAccount = async event => {
    event.preventDefault();

    const { data } = await axios({
      method: 'POST',
      url: 'http://localhost:4242/signup',
      data: {
        firstname: firstName,
        lastname: lastName,
        email: email,
        company: company,
        role: role

      },
    });
    setStatus(data);
  };

  const onSuccess = async res => {
    const { rV, uT, cu } = res.wt;
    setFirstName(rV);
    setLastName(uT);
    setEmail(cu);
    // const { data } = await axios({
    //   method: 'POST',
    //   url: '/signup',
    //   data: {
    //     firstname: rV,
    //     lastname: uT,
    //     email: cu,
    //   },
    // });
    // setStatus(data);
  };

  const setStatus = data => {
    console.log('Data:', data);
    if (data.status === 'error') setSignUpStatus(false);
    else if (data.status === 'OK') setSignUpStatus(true);
    setSignUpGoogle(true);
    console.log('signupstatus', signUpStatus);
    console.log('signupgoogle:', signUpGoogle);
  };
  const onFailure = err => {
    console.log('failed:', err);
  };

  const onSubmit = () => {
    console.log();
    dispatch(AuhMethods[method].onRegister({ firstName, email, password }));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Box className={classes.topComponent}>
          <Box className={classes.backButtonRoot}>
            <ArrowBack />
            <Typography>Back</Typography>
          </Box>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            Sign up as an organization
          </Typography>
          {signUpGoogle ? (
            signUpStatus ? (
              <Box className={classes.signUpMessage}>Your have Successfully signed up by Email</Box>
            ) : (
                <Box className={classes.errorMessage}>This email already exist </Box>
              )
          ) : (
              <>
                <Typography>Sign up with your work Google account or use the form.</Typography>
                <GoogleLogin
                  className={classes.googleSignUpButton}
                  clientId={clientId}
                  buttonText="Sign Up Using Google"
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  cookiePolicy={'single_host_origin'}
                  isSignedIn={false}
                />
              </>
            )}
        </Box>
        <Box className={classes.divider}>
          <Box className="horizontal_border"></Box>
          <Typography className="text">or</Typography>
          <Box className="horizontal_border"></Box>
        </Box>
        {signUpGoogle && signUpStatus ? (
          <Button className={classes.createAccountButton}>
            <Typography className={classes.btnText}>Login</Typography>
          </Button>
        ) : (
            <>
              <form className={classes.formRoot} onSubmit={handleCreateNewAccount}>
                <Box mb={2}>
                  <TextField
                    label={<IntlMessages id="First name" />}
                    fullWidth
                    // focused={true}
                    placeholder={'First name'}
                    onChange={event => setFirstName(event.target.value)}
                    defaultValue={firstName}
                    value={firstName}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    InputLabelProps={{ shrink: firstName !== '' }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label={<IntlMessages id="Last name" />}
                    fullWidth
                    placeholder={'Last name'}
                    onChange={event => setLastName(event.target.value)}
                    InputLabelProps={{ shrink: lastName !== '' }}
                    defaultValue={lastName}
                    value={lastName}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label={<IntlMessages id="Email address" />}
                    fullWidth
                    placeholder={'example@example.com'}
                    onChange={event => setEmail(event.target.value)}
                    defaultValue={email}
                    value={email}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={event => setPassword(event.target.value)}
                    defaultValue={password}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>

                <Box>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="role"><IntlMessages id="appModule.role" /></InputLabel>
                    <Select
                      className={classes.formSelect}
                      fullWidth
                      native
                      value={role}
                      onChange={event => setRole(event.target.value)}
                      label={<IntlMessages id="appModule.role" />}
                      inputProps={{
                        name: 'role',
                        id: 'role',
                      }}>
                      <option aria-label="None" value="" />
                      <option value={'CEO'}>CEO</option>
                      <option value={'Marketing Manager'}>Marketing Manager</option>
                      <option value={'Project Owner'}>Project Owner</option>
                      <option value={'other'}>Other</option>
                    </Select>
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <TextField
                    type="text"
                    label={<IntlMessages id="appModule.company" />}
                    fullWidth
                    onChange={event => setComapny(event.target.value)}
                    defaultValue={company}
                    margin="normal"
                    variant="outlined"
                    className={classes.textFieldRoot}
                  />
                </Box>
                {firstName && lastName && isValidEmail(email) && role && password &&
                  < Button className={classes.createAccountButton} type="submit">
                    <Typography className={classes.btnText}>Create Your Account</Typography>
                  </Button>
                }
              </form>
              {/* <Typography className={classes.textAcc}>
                By confirming your email, you agree to our <span className="terms">Terms of Service</span> and that you have
              read and understood our <span className="privacy"> Privacy Policy.</span>
              </Typography> */}
              <ContentLoader />
            </>
          )}
      </Box>
    </AuthWrapper >
  );
};

export default SignUp;