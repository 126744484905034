const today = new Date();
const currentYear = today.getFullYear();
const loginYear = 2021;

export const usersModule = {
  labelsList: [
    { id: 1, name: 'Banking', slug: 'banking', color: '#FF8C00' },
    { id: 2, name: 'Company', slug: 'company', color: '#00C4B4' },
    { id: 3, name: 'Payments', slug: 'payments', color: '#0F9AF7' },
  ],
  usersList: [
    // {
    //   id: 1457690400,
    //   name: 'Alex Dolgove',
    //   profile_pic: 'https://via.placeholder.com/150',
    //   email: 'alex-dolgove@example.com',
    //   emailUsage: 1.11, // GB
    //   phones: [{ phone: '(215)-659-7529', label: 'Home' }],
    //   designation: 'CEO',
    //   starred: false,
    //   company: 'TCS',
    //   labels: [2],
    //   status: 'active',
    //   suspendedBy: 'Admin',
    //   suspendedAt: new Date(),
    //   lastLoginAt: new Date(loginYear, 1, 1),
    //   createdAt: new Date(currentYear, 1, 1),
    //   updatedAt: new Date(currentYear, 1, 1),
    // },

  ],
};
