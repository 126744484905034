import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import AppTextInput from '../../../../vinter/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../@coremat/CmtList';
import AppCheckbox from '../../../../vinter/components/Common/formElements/AppCheckBox';
import { emailNotValid, requiredMessage } from '../../../../vinter/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { isValidEmail } from '../../../../vinter/utils/commonHelper';
import { addNewSubscriber, updateSubscriber } from '../../../../redux/actions/Subscribers';
import { KeyboardTimePicker } from '@material-ui/pickers';


const useStyles = makeStyles(theme => ({
  filterItem: {
    padding: '5px 10px',
    color: theme.palette.common.dark,
    '& .MuiCheckbox-root': {
      color: theme.palette.common.dark,
      marginRight: 10,
    },
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} format="(###) ###-####" />;
}

const labels = [
  { title: 'PCF', slug: 'pcf' },
  { title: 'EOD', slug: 'eod' },
  { title: 'NAV', slug: 'nav' },
];

const splitName = subscriber => {
  if (subscriber) {
    const [fName, mName, lName] = subscriber.name.split(' ');
    return [fName, lName ? mName + ' ' + lName : mName];
  }

  return ['', ''];
};

const filterList = [
  { title: 'PCF', slug: 'pcf' },
  { title: 'EOD', slug: 'eod' },
  { title: 'NAV', slug: 'nav' },
];

const AddEditSubscriber = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const currentSubscriber = useSelector(({ subscribersReducer }) => subscribersReducer.currentSubscriber);
  const [email, setEmail] = useState(currentSubscriber ? currentSubscriber.email : '');
  const [time, setTime] = useState(currentSubscriber ? currentSubscriber.time : '');
  const [emailError, setEmailError] = useState('');
  const [listsToShow, setListsToShow] = useState(currentSubscriber ? currentSubscriber.files : []);
  const [selectedDate, setSelectedDate] = useState(currentSubscriber ? new Date("1970/2/2 " + currentSubscriber.time) : new Date());

  const handleDateChange = date => {
    setSelectedDate(date)
    setTime(date.format("HH:mm") + ":00");
  };
  const dispatch = useDispatch();

  const onSubmitClick = () => {

    if (!email) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(email)) {
      setEmailError(emailNotValid);
    }

    else {
      onSubscriberSave();
    }
  };

  const onSubscriberSave = () => {
    console.log(localStorage.getItem("nav_id"), "nav_id", time)

    const subscriberDetail = {

      email,
      time,
      files: [...listsToShow],
      "email_enabled": true,
      "nav_id": localStorage.getItem("nav_id"),
    };
    if (currentSubscriber) {
      dispatch(
        updateSubscriber({ ...currentSubscriber, ...subscriberDetail }, () => {
          onCloseDialog();
        }),
      );
    } else {
      dispatch(
        addNewSubscriber(subscriberDetail, () => {
          onCloseDialog();
        }),
      );
    }

  };


  const updatedFilterList = item => event => {
    if (event.target.checked) {
      setListsToShow([...listsToShow, item.slug]);
    } else {
      setListsToShow(listsToShow.filter(slug => slug !== item.slug));
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{currentSubscriber ? 'Edit Subscriber Details' : 'Create New Subscriber'}</DialogTitle>
      <DialogContent dividers>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant="outlined"
            label="Email Address"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            helperText={emailError}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            label="Time"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </Box>
        <CmtList
          data={filterList}
          renderRow={(item, index) => (
            <Box key={index} className={classes.filterItem}>
              <AppCheckbox label={item.title} checked={listsToShow.includes(item.slug)} onChange={updatedFilterList(item)} />
            </Box>
          )}
        />
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditSubscriber;

AddEditSubscriber.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
