import React from 'react';
import Box from '@material-ui/core/Box';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TLineChart from '../LineChart';
import CmtMediaObject from '../../../../../@coremat/CmtMediaObject';
import CmtImage from '../../../../../@coremat/CmtImage';
import { timeFromNow } from '../../../../../vinter/utils/dateHelper';
import CmtCarousel from '../../../../../@coremat/CmtCarousel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  mediaObjectRoot: {
    width: '100%',
    display: 'flex',
    '@media screen and (max-width: 699px)': {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& .Cmt-media-image': {
      marginRight: 24,
      '@media screen and (max-width: 699px)': {
        alignSelf: 'unset',
        marginRight: 0,
        marginBottom: 16,
        width: '100%',
      },
    },
  },
  carouselRoot: {
    marginRight: 0,
    '& .bottom-left .slick-dots': {
      left: 10,
    },
    '& .slick-dots': {
      bottom: 15,
      '& li button:before': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
      '& li.slick-active button:before': {
        backgroundColor: theme.palette.warning.main,
      },
    },
  },
  titleRoot: {
    letterSpacing: 0.15,
    fontSize: 16,
    marginBottom: 12,
    fontWeight: theme.typography.fontWeightRegular,
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    marginBottom: 16,
    display: 'inline-block',
  },
  subTitleRoot: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginBottom: 8,
  },
  iconRoot: {
    fontSize: 18,
    marginRight: 6,
  },
  linkBtn: {
    fontSize: 14,
    color: theme.palette.primary.main,
    letterSpacing: 1.25,
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer',
    display: 'inline-block',
  },
  priceRoot: {
    fontSize: 16,
    color: theme.palette.primary.main,
    marginBottom: 5,
    fontWeight: theme.typography.fontWeightRegular,
  },
  footerComponentRoot: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: 16,
      textAlign: 'left',
    },
  },
  containerStyle: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 250,
    },
    [theme.breakpoints.up('md')]: {
      width: 315,
    },
    '@media screen and (max-width: 699px)': {
      width: '100%',
    },
    '& .slick-slide img': {
      borderRadius: 4,
      display: 'block !important',
    },
  },
}));

const PropertyItem = ({ item, onPropertyClick, itemNo }) => {
  const classes = useStyles();

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        fontSize={12}
        // color="text.disabled"
        display="flex"
        flexDirection="row"
        alignItems="center"
        // mb={4}
        sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            width: '100%',
            paddingTop: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography style={{ color: 'black', flex: '0.075 ', fontSize: '12px', fontWeight: '700' }}>{itemNo}</Typography>
          <Typography style={{ flex: '0.3 ', fontSize: '13px', fontWeight: '700' }}>{item.name}</Typography>
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              color: '#1f9e99',
              flex: '0.15 ',
              textAlign: 'right',
              fontSize: '10px',
            }}>
            {item.value}
            <Box fontSize={12} ml={2}>
              <ion-icon name="caret-up-outline"></ion-icon>
            </Box>
          </Typography>
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              color: '#1f9e99',
              flex: '0.15 ',
              textAlign: 'right',
              fontSize: '10px',
            }}>
            {`${item.hour1}%`}
            <Box fontSize={12} ml={2}>
              <ion-icon name="caret-up-outline"></ion-icon>
            </Box>
          </Typography>
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              color: '#1f9e99',
              flex: '0.15 ',
              textAlign: 'right',
              fontSize: '10px',
            }}>
            {`${item.hours24}%`}
            <Box fontSize={12} ml={2}>
              <ion-icon name="caret-up-outline"></ion-icon>
            </Box>
          </Typography>
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              color: 'red',
              flex: '0.15 ',
              textAlign: 'right',
              fontSize: '10px',
            }}>
            {`${item.days7}%`}
            <Box fontSize={12} ml={2}>
              <ion-icon name="caret-down-outline"></ion-icon>
            </Box>
          </Typography>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              color: 'red',
              flex: '0.5 ',
              textAlign: 'right',
              fontSize: '10px',
            }}>
            <TLineChart />
          </Box>
        </Box>
        <Button
          styel={{ width: '50px', height: '50px', borderRadius: '50%', background: 'gray' }}
          color="primary"
          onClick={() => onPropertyClick(item, itemNo)}>
          <ArrowDropUpIcon className={classes.iconRoot} />
        </Button>
      </Box>
    </Box>
  );
};

export default PropertyItem;
