import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';

const Logo = ({ color, ...props }) => {
  // const logoUrl = color === 'white' ? '/images/logo-white.png' : '/images/logo.png';
  // const logoSymbolUrl = color === 'white' ? '/images/logo-white-symbol.png' : '/images/logo-symbol.png';
  const logoUrl = '//images.squarespace-cdn.com/content/v1/5d1c6b091629cd0001317c9e/c0843cd3-d542-4049-b13c-f64d8bb01858/VinterLogoRoundedsquare.png?format=1500w';
  const logoSymbolUrl = '//images.squarespace-cdn.com/content/v1/5d1c6b091629cd0001317c9e/c0843cd3-d542-4049-b13c-f64d8bb01858/VinterLogoRoundedsquare.png?format=1500w';
  // images.squarespace-cdn.com/content/v1/5d1c6b091629cd0001317c9e/c0843cd3-d542-4049-b13c-f64d8bb01858/VinterLogoRoundedsquare.png?format=1500w

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/">
          <CmtImage className="logo" src={logoUrl} alt="logo" />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage src={logoSymbolUrl} alt="logo" />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
