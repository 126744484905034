import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import CmtCard from '../../../../../@coremat/CmtCard';
import withStyles from '@material-ui/core/styles/withStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import StockChart from '../StockChart';

const useStyles = makeStyles(theme => ({
  progressBarRoot: {
    display: 'flex',
    height: '30px',
    marginTop: '3px',
  },
  buttonsRoot: {
    display: 'flex',
    gridGap: '30px',
    marginTop: '40px',
    '& button': {
      padding: '12px 25px',
      background: 'Transparent',
      border: '2px solid #183554',
      color: '#183554',
      fontWeight: '700',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    '& button.fill': {
      backgroundColor: '#183554',
      color: '#fff',
    },
  },
  piChartRoot: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    '& .piChart': {
      // background: 'gray',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      margin: '30px 30px 30px 0',
      maxWidth: '98%',
      height: '350px',
      '& #stock-chart': {
        width: '100%',
        height: '100%',
      },
    },
  },
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const PropertyDetail = ({ selectedProperty, showPropertyList }) => {
  const classes = useStyles();
  const { details: { points, coinsStatus } = {} } = selectedProperty;
  let value = 0;
  coinsStatus.map(item => (value += item.value));
  value = 100 / value;

  return (
    <CmtCard>
      <Box className={classes.progressBarRoot}>
        {coinsStatus.map((item, index) => {
          return (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">{item.name}</Typography>
                  <b>{'Value: ' + item.value}</b>
                </React.Fragment>
              }>
              <Box
                sx={{
                  cursor: 'pointer',
                  background: `${item.color}`,
                  borderRight: `${coinsStatus.length === index + 1 ? '' : '2px solid #fff'}`,
                  fontWeight: 700,
                  color: '#FFF',
                  width: `${value * item.value}%`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: ' 0.5px 0.5px 10px 0 rgba(0, 0, 0, 0.1)',
                }}>
                {' '}
                {item.name}
              </Box>
            </HtmlTooltip>
          );
        })}
      </Box>
      <Box display="flex">
        <Box display="flex" flex={1} flexDirection="column" justifyContent={'center'} m=" 30px 0 30px  30px" px={6} py={3}>
          <Box fontSize="25px" fontWeight="700" mb={5} color="#183554">
            {selectedProperty?.name}
          </Box>
          {points?.map((item, index) => {
            return (
              <Box display="flex" alignItems="center">
                <Box fontSize={10} display="flex" alignItems="center">
                  <ion-icon name="radio-button-on-outline"></ion-icon>
                </Box>
                <Box ml={2}> {item}</Box>
              </Box>
            );
          })}

          <Box className={classes.buttonsRoot}>
            <button onClick={() => { }}> COMPONENTS</button>
            <button onClick={() => { }}> METHODOLOGY</button>
            <button className="fill" onClick={() => { }}>
              HISTORY
            </button>
          </Box>
        </Box>

      </Box>
      <Box className={classes.piChartRoot}>
        <Box className="piChart">
          <Box id="chartcontrols"></Box>
          <StockChart chartID="stock-chart" />
        </Box>
      </Box>
    </CmtCard>
  );
};

export default PropertyDetail;
