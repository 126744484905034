import React from 'react';
import { CryptoCard } from '../../../../vinter/components/Common';
import IndexGraph from './IndexGraph';

const IndexHistory = ({ index, amount, data }) => {
  return (
    <CryptoCard title={`${index} Price`} amount={`$ ${amount}`} progress={{ value: '23%', icon: 'info', color: '#fff' }}>
      <IndexGraph data={data} />
    </CryptoCard>
  );
};

export default IndexHistory;
