import React, { useState, useEffect } from 'react';
import { Box, Divider, makeStyles } from '@material-ui/core';
import AppSwitch from '../../../../Common/formElements/AppSwitch';
import Button from '@material-ui/core/Button';
import AppTextInput from '../../../../Common/formElements/AppTextInput';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../../../../services/auth';
import { CurrentAuthMethod } from '../../../../../constants/AppConstants';
import axios from "../../../../../../services/auth/jwt/config"

const useStyles = makeStyles(theme => ({
  sectionRoot: {
    '&:not(:last-child)': {
      marginBottom: 28,
    },
  },
  sectionHeading: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginBottom: 20,
    textTransform: 'uppercase',
  },
  labelProps: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
  },
  helpertext: {
    color: "rgba(0, 0, 0, 0.6)",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: 1.66
  },
  helpertextlabel: {
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "pointer",
    margin: 0,
    fontSize: "1rem",
    marginTop: "3px",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: 1.66,
    display: "block",
    marginBottom: "10px"
  },
  qrcode: {
    width: '200px'
  }
}));

var generalConfig = {};

const Settings = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);

  const selectedIndex = useSelector(({ common }) => common.selectedIndex);
  const dispatch = useDispatch();
  console.log(authUser)
  const [emailNotification, setEmailNotification] = useState(authUser.emailEnabled);
  const [doubleFactorAuth, setDoubleFactorAuth] = useState(authUser.otpEnabled);
  const [pcfFormat, setPcfFormat] = useState(authUser.pcfFormat);
  const [navFormat, setNavFormat] = useState(authUser.navFormat);
  const [eodFormat, setEodFormat] = useState(authUser.eodFormat);

  const [imgsrc, setImgsrc] = useState("")

  const getQr = (evnt) => {


    generalConfig.url = '/otp/url'
    generalConfig.method = "get"

    axios(generalConfig).then((response) => {
      setImgsrc('data:image/png;base64,' + response.data)

    });
  }

  useEffect(() => {
    getQr()
    console.log(localStorage.getItem("nav_id"), "nav_id")

  }, [])

  // useEffect(() => {
  //   setEDoubleFactorAuth()
  // }, [authUser])

  return (
    <Box>
      <Box fontSize={22} fontWeight={700} mb={9}>
        Settings
      </Box>
      <Box className={classes.sectionRoot}>
        <Box className={classes.sectionHeading}>Notification Settings</Box>
        <Box pb={6}>
          <AppSwitch
            label="Email Notification"
            labelProps={{ className: classes.labelProps }}
            helperText="By turning off email notifications, no EOD, PCF and NAV files will be sent out to subscribers."
            checked={emailNotification}
            onChange={event => {
              setEmailNotification(event.target.checked)
              dispatch(AuhMethods[CurrentAuthMethod].setNotification(false, '', doubleFactorAuth, event.target.checked, pcfFormat, eodFormat, navFormat, "email"));
            }}
          />
        </Box>
        <Divider />
        {/* <Box pt={3} pb={6}>
          <AppSwitch
            label="SMS Notifications"
            labelProps={{ className: classes.labelProps }}
            helperText="+91 957 858 9855, +91 358 788 596"
            checked={smsNotification}
            onChange={event => setSMSNotification(event.target.checked)}
          />
        </Box>
        <Divider />
      </Box>
      <Box className={classes.sectionRoot}>
        <Box className={classes.sectionHeading}>System Settings</Box>
        <Box pb={6}>
          <AppSwitch
            label="Upload log everyday"
            labelProps={{ className: classes.labelProps }}
            helperText="This will upload activity log to server everyday"
            checked={logEveryday}
            onChange={event => setLogEveryday(event.target.checked)}
          />
        </Box>
        <Divider />
        <Box pt={3} pb={6}>
          <AppSwitch
            label="Cloud Storage"
            labelProps={{ className: classes.labelProps }}
            helperText="25gb / 1tb is used "
            checked={cloudStorage}
            onChange={event => setCloudStorage(event.target.checked)}
          />
        </Box>
        <Divider /> */}
        <Box className={classes.sectionRoot}></Box>
        <Box className={classes.sectionHeading}>Security Settings</Box>
        <Box pt={3} pb={6}>

          <AppSwitch
            label="2 Factor Authentication"
            labelProps={{ className: classes.labelProps }}
            helperText={doubleFactorAuth ? "Scan the QRcode Below in Google Authentication" : "Set-up your 2 factor authentication now."}
            checked={doubleFactorAuth}
            onChange={(event) => {
              setDoubleFactorAuth(event.target.checked)
              dispatch(AuhMethods[CurrentAuthMethod].updateAuthUser(false, '', event.target.checked, emailNotification, pcfFormat, eodFormat, navFormat, "otp"));
            }}
          />
          {doubleFactorAuth &&
            <Box pt={3} pb={6}>
              <img className={classes.qrcode} src={imgsrc} />
            </Box>
          }
        </Box>
        <Divider />
      </Box>
      <Box className={classes.sectionRoot}>
        <Box className={classes.sectionHeading}>Filename Settings</Box>
        <Box pt={3} pb={6} className={classes.helpertext}>
          <label className={classes.helpertextlabel}>File Names</label>
          The standard file name consists of a prefix, the instrument’s ticker and the current date. Vinter uses YYYY.MM.DD as its standard date format. The standard filename thus  look like <br />
          <strong>{"<PREFIX>_<TICKER>_YYYY.MM.DD"}</strong>.<br /><br />
          There are three different prefixes. EOD for end-of-day files. NAV for net asset value files and PCF for portfolio composition files.


          <br /><br />You can change the file name for each type of file using the below fields. Note that the date suffix always has the YYYY.MM.DD format.<br />

          <br />
          <AppTextInput
            fullWidth
            label="NAV Format"
            variant="outlined"
            value={navFormat}
            onChange={(event) => {
              setNavFormat(event.target.value)

            }}
          />
          <br />
          <br />
          <AppTextInput
            fullWidth
            label="EOD Format"
            variant="outlined"
            value={eodFormat}
            onChange={(event) => {
              setEodFormat(event.target.value)

            }}
          />
          <br />
          <br />
          <AppTextInput
            fullWidth
            label="PCF Format"
            variant="outlined"
            value={pcfFormat}
            onChange={(event) => {
              setPcfFormat(event.target.value)

            }}
          />
          <br />
          <Button className={classes.addTaskBtn} variant="contained" color="primary" style={{ marginTop: "10px" }} onClick={(event) => {

            dispatch(AuhMethods[CurrentAuthMethod].updateAuthUser(false, '', doubleFactorAuth, emailNotification, pcfFormat, eodFormat, navFormat, "format"));
          }} >
            <Box component="span"  >
              Update Format
              </Box>
          </Button>

        </Box>
        <Divider />
      </Box>

    </Box >
  );
};

export default Settings;
