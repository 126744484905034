import GroupIcon from '@material-ui/icons/Folder';
import React from 'react';
import StarIcon from '@material-ui/icons/Folder';
import AccessTimeIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Folder';

export const foldersList = [
  { id: 1, name: 'Portfolio Composition Files ', slug: 'pcf', icon: <GroupIcon /> },
  { id: 2, name: 'Net Asset Values ', slug: 'nav', icon: <StarIcon /> },
  { id: 3, name: 'Rebalance Weights ', slug: 'rebalance_weights_files', icon: <AccessTimeIcon /> },
  { id: 4, name: 'End-of-Day Values', slug: 'eod', icon: <DeleteIcon /> },
];

export const labelsList = [];

export const contacts = [];
