import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useSelector } from 'react-redux';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import TimeToLeave from '@material-ui/icons/TimeToLeave';
import AttachFileSharp from '@material-ui/icons/AttachFileSharp';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const SubscriberDetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { currentSubscriber } = useSelector(({ subscribersReducer }) => subscribersReducer);

  const { email, files, time } = currentSubscriber;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.subscriberInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">


          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>{email}</Typography>
              <Box ml={1}>
                {/* <Checkbox
                  icon={<StarBorderIcon />}
                  checkedIcon={<StarIcon style={{ color: '#FF8C00' }} />}
                  checked={starred}
                /> */}
              </Box>
            </Box>

          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          {/* <Box ml={1}>
            <Tooltip title={is_active}>
              <IconButton aria-label="filter list">
                {!is_active && <Block color="primary" />}
                {is_active && <CheckCircleOutline color="primary" />}
              </IconButton>
            </Tooltip>
          </Box> */}
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} component="p" color="common.dark">
          Subscriber Detail
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <EmailIcon />
          <Box ml={5} color="primary.main" component="p" className="pointer">
            {email}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <AttachFileSharp />
          <Box ml={5} color="primary.main" component="p" className="pointer">
            {files.map((file, index) => (
              <span key={index}>{file} ,&nbsp;</span>
            ))}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <TimeToLeave />
          <Box ml={5} color="primary.main" component="p" className="pointer">
            {time}
          </Box>
        </Box>
        {/* <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          <PhoneIcon />
          <Box ml={5}>
            <CmtList
              data={phones}
              renderRow={(item, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <Box color="text.secondary">{item.phone}</Box>
                  <Box ml={2} className={classes.labelRoot}>
                    {item.label}
                  </Box>
                </Box>
              )}
            />
          </Box>
        </Box> */}
      </Box>
    </Dialog>
  );
};

export default SubscriberDetailView;

SubscriberDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
