import React from 'react';
import Box from '@material-ui/core/Box';
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import MarketIndexHeader from './MarketIndexHeader';
import PropertiesDataList from './PropertiesDataList';
import { alpha, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  headerRoot: {
    paddingBottom: 0,
    paddingTop: 0,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      '&.Cmt-header-root': {
        flexDirection: 'column',
      },
      '& .Cmt-action-default-menu': {
        position: 'absolute',
        right: 24,
        top: 5,
      },
    },
  },
  cardContentRoot: {
    padding: '0 !important',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    marginTop: -1,
  },
}));

const PropertiesList = ({ onPropertyClick, data, selectedProperty, showPropertyList }) => {
  const classes = useStyles();
  return (
    <Box>
      {/* <CmtCardHeader className={classes.headerRoot} title={<MarketIndexHeader />}></CmtCardHeader> */}
      <CmtCardContent className={classes.cardContentRoot}>
        <PropertiesDataList
          data={data}
          onPropertyClick={onPropertyClick}
          selectedProperty={selectedProperty}
          showPropertyList={showPropertyList}
        />
      </CmtCardContent>
    </Box>
  );
};

export default PropertiesList;
