import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
const data = [
  { name: 'Page A', pv: 1400 },
  { name: 'Page B', pv: 500 },
  { name: 'Page C', pv: 9800 },
  { name: 'Page D', pv: 1000 },
  { name: 'Page E', pv: 8000 },
  { name: 'Page F', pv: 500 },
  { name: 'Page G', pv: 675 },
  { name: 'Page G', pv: 1065 },
  { name: 'Page G', pv: 675 },
  { name: 'Page G', pv: 15 },
  { name: 'Page G', pv: 10675 },
  { name: 'Page G', pv: 1 },
  { name: 'Page G', pv: 75 },
  { name: 'Page G', pv: 175 },
  { name: 'Page A', pv: 1400 },
  { name: 'Page B', pv: 50 },
  { name: 'Page C', pv: 9800 },
  { name: 'Page D', pv: 100 },
  { name: 'Page E', pv: 8000 },
  { name: 'Page F', pv: 500 },
  { name: 'Page G', pv: 1065 },
  { name: 'Page G', pv: 10675 },
  { name: 'Page G', pv: 10675 },
  { name: 'Page G', pv: 1675 },
  { name: 'Page G', pv: 1075 },
  { name: 'Page G', pv: 17 },
  { name: 'Page G', pv: 10675 },
  { name: 'Page G', pv: 1067 },
];

const TLineChart = () => (
  <ResponsiveContainer width="60%" height={50} mar>
    <LineChart data={data}>
      <Line type="linear" dataKey="pv" stroke="#ee0000" dot={false} />
    </LineChart>
  </ResponsiveContainer>
);

export default TLineChart;
