import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Apps from './Apps';
import Login from './Auth/Login';
import Signup from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import Otp from './Auth/Otp';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UsersModule from './modules/Users';
import SubscribersModule from './modules/Subscribers';
import MarketIndex from './Dashboards/Listing/MarketIndex';
import MarketIndex2 from './Dashboards/Listing/MarketIndex2';
import Dashboard from './Dashboards/Indexes';
import ApikeysModule from './modules/Apikeys';
import Shop from './Shop/Indexes';

// import LayoutBuilder from './LayoutBuilder';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: props.location },
              }}
            />
          )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/signin'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/home" component={MarketIndex2} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/shop" component={Shop} />
        <Route path="/apps" component={Apps} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/users" component={UsersModule} />
        <Route path="/subscribers" component={SubscribersModule} />
        <Route path="/apikeys" component={ApikeysModule} />
        <Route path="/otp" component={Otp} />

        {/*<Route path="/layout-builder" component={LayoutBuilder} />*/}
      </Switch>

      {location.pathname !== '/signin' && location.pathname !== '/signup' && location.pathname !== '/forgot-password' && location.pathname !== '/otp' && (
        <></>
      )}
    </React.Fragment>
  );
};

export default Routes;
