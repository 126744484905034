import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  ADD_SUBSCRIBER,
  DELETE_BULK_SUBSCRIBERS,
  DELETE_SUBSCRIBER,
  EDIT_SUBSCRIBER,
  GET_SUBSCRIBERS,
  SET_SUBSCRIBER_DETAILS,
} from '../../vinter/constants/ActionTypes';

export const getSubscribers = (filterOptions = [], searchTerm = '', callbackFun, index) => {
  return dispatch => {
    dispatch(fetchStart());
    if (!index) {
      window.location.href = "/dashboard"
    }


    axios
      .get('https://ficasapi.vinterindex.com/api/v1/nav/ticker/' + index)
      .then(data => {
        console.log("all subscribers", data.data.subscription)

        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_SUBSCRIBERS, payload: data.data.subscription });
          if (callbackFun) callbackFun(data.data.subscription);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {

        dispatch(fetchError('There was something issue in responding server'));
        if (error.response.status === 403) {
          window.location.href = '/signin';
        }
      });
  };
};

export const setCurrentSubscriber = Subscriber => {
  return dispatch => {
    dispatch({ type: SET_SUBSCRIBER_DETAILS, payload: Subscriber });
  };
};

export const addNewSubscriber = (Subscriber, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    console.log("Subscriber", Subscriber)


    axios
      .post('https://ficasapi.vinterindex.com/api/v1/subscription/', Subscriber)
      .then(data => {

        if (data.status === 200) {
          dispatch(fetchSuccess('New Subscriber was added successfully.'));
          console.log("data returned =>", data)
          dispatch({ type: ADD_SUBSCRIBER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {

        dispatch(fetchError('There was something issue in responding server'));
        if (error.response.status === 403) {
          window.location.href = '/signin';
        }
      });
  };
};

export const sentMailToSubscriber = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to Subscriber successfully'));
  };
};

export const updateSubscriber = (Subscriber, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('https://ficasapi.vinterindex.com/api/v1/subscription/' + Subscriber.id, Subscriber)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected Subscriber was updated successfully.'));
          dispatch({ type: EDIT_SUBSCRIBER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const updateSubscriberStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/Subscribers/update-status', data)
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Subscriber status was updated successfully.'));
          dispatch({ type: EDIT_SUBSCRIBER, payload: response.data });
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteBulkSubscribers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/Subscribers/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected Subscribers were deleted successfully.'));
          dispatch({ type: DELETE_BULK_SUBSCRIBERS, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
      });
  };
};

export const deleteSubscriber = (Id, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('https://ficasapi.vinterindex.com/api/v1/subscription/' + Id)
      .then(data => {

        if (data.status === 200) {
          dispatch(fetchSuccess('Selected Subscriber was deleted successfully.'));
          dispatch({ type: DELETE_SUBSCRIBER, payload: Id });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
        if (error.response.status === 403) {
          window.location.href = '/signin';
        }
      });
  };
};
