import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Dashboard from './Dashboard';

import ContactApp from './ContactApp';

import Auth from './Auth';
import Users from './Users';
import Subscribers from './Subscribers';
import Apikeys from './Apikeys';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    dashboard: Dashboard,
    auth: Auth,
    contactApp: ContactApp,
    usersReducer: Users,
    subscribersReducer: Subscribers,
    apikeysReducer: Apikeys,
  });
