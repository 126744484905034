import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  ADD_APIKEY,
  DELETE_BULK_APIKEYS,
  DELETE_APIKEY,
  EDIT_APIKEY,
  GET_APIKEYS,
  SET_APIKEY_DETAILS,
} from '../../vinter/constants/ActionTypes';

export const getApikeys = (filterOptions = [], searchTerm = '', callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/org/apikeys', { params: { filterOptions, searchTerm } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_APIKEYS, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
        if (error.response.status === 401) {
          window.location.href = "/signin";
        }
      });
  };
};

export const setCurrentApikey = apikey => {
  return dispatch => {
    dispatch({ type: SET_APIKEY_DETAILS, payload: apikey });
  };
};

export const addNewApikey = (apikey, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    console.log("apikey", apikey)

    axios
      .post('/org/apikeys', apikey)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('New apikey was added successfully.'));
          console.log("data returned =>", data)
          dispatch({ type: ADD_APIKEY, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        console.log(error)
        dispatch(fetchError('There was something issue in responding server'));
        if (error.response.status === 401) {
          window.location.href = "/signin";
        }
      });
  };
};

export const deleteApikey = (apikeyId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/org/apikeys/' + apikeyId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected apikey was deleted successfully.'));
          dispatch({ type: DELETE_APIKEY, payload: apikeyId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
        if (error.response.status === 401) {
          window.location.href = "/signin";
        }
      });
  };
};
