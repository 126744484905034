import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { propertyTabCategories } from '../../../../../@fake-db';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  headerRoot: {
    width: '100%',
    padding: '10px 0',
    display: 'flex',
  },
}));

const MarketIndexHeader = () => {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ width: '100%', padding: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography style={{ flex: '0.075', fontWeight: '700' }}>#</Typography>
        <Typography style={{ flex: '0.3', textAlign: 'center', fontSize: '12px', fontWeight: '700' }}>Name</Typography>
        <Typography style={{ flex: '0.15', textAlign: 'center', fontSize: '12px', fontWeight: '700' }}>Value</Typography>
        <Typography style={{ flex: '0.15', textAlign: 'center', fontSize: '12px', fontWeight: '700' }}>1h%</Typography>
        <Typography style={{ flex: '0.15', textAlign: 'center', fontSize: '12px', fontWeight: '700' }}>24h%</Typography>
        <Typography style={{ flex: '0.15', textAlign: 'center', fontSize: '12px', fontWeight: '700' }}>7d%</Typography>
        <Typography style={{ flex: '0.5', textAlign: 'center', fontSize: '12px', fontWeight: '700' }}>Last 7 days</Typography>
      </Box>
    </>
  );
};

export default MarketIndexHeader;
