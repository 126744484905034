import axios from 'axios';

export default axios.create({
  baseURL: `https://user.vinterindex.com`, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});

export const etpaxios = axios.create({
  //baseURL: `https://auth.vinter.co`, //YOUR_API_URL HERE
  baseURL: `https://ficasapi.vinterindex.com`, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
